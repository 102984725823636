// Need to import pollyfils for react-table current work on ie11
import 'core-js/features/number';
import 'core-js/features/array';
import 'core-js/features/object/assign';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history, sagaMiddleware } from 'store';
import rootSaga from 'sagas';
import { unregister } from 'utils/registerServiceWorker';
import App from 'containers/App';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from 'config/theme';
import config from 'constants/config';
import * as Sentry from '@sentry/react';

const environment = process.env.REACT_APP_ENV;

if (config.SENTRY_DSN && (environment === 'staging' || environment === 'production')) {
  Sentry.init({ dsn: config.SENTRY_DSN, environment: process.env.REACT_APP_ENV });
}

if (environment === 'development') {
  window.store = store;
}

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en">
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </IntlProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

unregister();
