
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { NewCheckbox } from 'components/Form/NewCheckbox';
import { Form, Field } from 'react-final-form';
import { Button } from 'ui/Button';

const CalendarItems = ({calendarData, submitForm, calendarsUpdated, handleCheckbox, showBtn}) => {
    return (
        <Form                    
            initialValues={calendarData.chosenCalendares}
            onSubmit={submitForm}
            render={({ handleSubmit }) => (
            <CheckboxWrap onSubmit={handleSubmit}>
                {calendarData.calendarList.map((calendar) => (
                    <Checkbox key={calendar.id} color={calendar.backgroundColor}>
                        <Field
                            onClick={({target}) => handleCheckbox(target, calendar.id)}
                            component={NewCheckbox}
                            label={
                                calendar.summary === calendarData.email ? 
                                    'My calendar' : calendar.summary
                            }
                            name={
                                calendar.summary === calendarData.email ? 
                                    'myCalendar' : calendar.summary
                            }
                            type="checkbox"
                        />
                    </Checkbox>
                ))}
                {!!calendarsUpdated.length && showBtn && <Button type="submit">Save changes</Button>}
            </CheckboxWrap>
            )}
        />
    )
}

export default CalendarItems;


const CheckboxWrap = styled.form`
    display: block;
    padding: 26px 0;
    border-top: 1px solid #E8E9ED;
    margin: 0px 25px;
`;

const Checkbox = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  
  label {
    margin-bottom: 0;

    input:checked + div{
        background: ${(p) => p.color};
    }

    div:nth-child(2){
        border: 2px solid ${(p) => p.color};
        border-radius: 1px;
    }

    svg path {
        fill: white;
    }
    
  }

  &:last-child{
    margin-bottom: 0;
  }
`;
