import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { showModal } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { userTypeSelector } from 'selectors/userType';

import {
  getLenderDetailsSuccess,
  getLenderDetailsFailure,
  addTagToLenderSuccess,
  addTagToLenderFailure,
  removeTagFromLenderSuccess,
  removeTagFromLenderFailure,
  addNoteToLenderSuccess,
  addNoteToLenderFailure,
  getLenderActivitiesSuccess,
  getLenderActivitiesFailure,
  archiveLenderSuccess,
  archiveLenderFailure,
  getBbDetailsFailure,
  getBbDetailsSuccess,
  lenderResendInvitationSuccess,
  lenderResendInvitationFailure,
  addTagToClientSuccess,
  addTagToClientFailure,
  removeTagFromClientSuccess,
  removeTagFromClientFailure,
  addNoteToClientSuccess,
  addNoteToClientFailure,
  getUsersSuccess,
  getUsersFailure,
} from './actions';

function* getDetails(action) {
  try {
    const { id } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/lenders/invite/${id}`);

    const tags = yield call(API.axiosApi.get, '/lender-tags/');
    const updatedTags = tags.data.response.map((tag) => ({ label: tag, value: tag }));

    yield put(
      getLenderDetailsSuccess({
        data: {
          ...response,
          tags: response.tags.map((tag) => ({ label: tag, value: tag })),
        },
        tags: updatedTags,
      })
    );
  } catch (e) {
    yield put(getLenderDetailsFailure({ message: getError(e) }));
  }
}
function* getBbDetails(action) {
  try {
    const { id } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/clients/${id}`);

    const userType = userTypeSelector(yield select());
    const isCurrentUserBB = userType.borrower || userType.broker;

    if (!isCurrentUserBB) {
      const tags = yield call(API.axiosApi.get, '/client-tags/');
      const updatedTags = tags.data.response.map((tag) => ({ label: tag, value: tag }));

      yield put(
        getBbDetailsSuccess({
          data: {
            ...response,
            tags: response.tags.map((tag) => ({ label: tag, value: tag })),
          },
          tags: updatedTags,
        })
      );
    } else {
      yield put(
        getBbDetailsSuccess({
          data: {
            ...response,
            isCurrentUserBB,
          },
        })
      );
    }
  } catch (e) {
    yield put(getBbDetailsFailure({ message: getError(e) }));
  }
}

function* addTagToLender(action) {
  const { tagName, userId } = action.payload;
  try {
    yield call(API.axiosApi.post, `/tag-lender/${userId}/`, { text: tagName });
    yield put(addTagToLenderSuccess({ tagName }));
  } catch (e) {
    console.log(e);
    yield put(addTagToLenderFailure({ message: getError(e) }));
  }
}

function* removeTagFromLender(action) {
  const { tagName, userId } = action.payload;
  try {
    yield call(API.axiosApi.delete, `/tag-lender/${userId}/`, {
      data: { text: tagName },
    });
    yield put(removeTagFromLenderSuccess({ tagName }));
  } catch (e) {
    console.log(e);
    yield put(removeTagFromLenderFailure({ message: getError(e) }));
  }
}

function* addNoteToLender(action) {
  const { note, userId } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/note-lender/${userId}/`, { text: note.note });

    yield put(addNoteToLenderSuccess({ response }));
  } catch (e) {
    console.log(e);
    yield put(addNoteToLenderFailure({ message: getError(e) }));
  }
}
function* addTagToClient(action) {
  const { tagName, userId } = action.payload;
  try {
    yield call(API.axiosApi.post, `/tag-client/${userId}/`, { text: tagName });
    yield put(addTagToClientSuccess({ tagName }));
  } catch (e) {
    console.log(e);
    yield put(addTagToClientFailure({ message: getError(e) }));
  }
}

function* removeTagFromClient(action) {
  const { tagName, userId } = action.payload;
  try {
    yield call(API.axiosApi.delete, `/tag-client/${userId}/`, {
      data: { text: tagName },
    });
    yield put(removeTagFromClientSuccess({ tagName }));
  } catch (e) {
    console.log(e);
    yield put(removeTagFromClientFailure({ message: getError(e) }));
  }
}

function* addNoteToClient(action) {
  const { note, userId } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/note-client/${userId}/`, { text: note.note });

    yield put(addNoteToClientSuccess({ response }));
  } catch (e) {
    console.log(e);
    yield put(addNoteToClientFailure({ message: getError(e) }));
  }
}

function* getLenderActivities(action) {
  const { lenderId, page, filter } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/events/lenders?lender=${lenderId}&page=${page || 1}${
        filter ? `&event_category=${filter}` : ''
      }`
    );

    yield put(
      getLenderActivitiesSuccess({
        activities: response.results,
        nextActivities: response.next,
        lenderId,
        page,
        filter,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(getLenderActivitiesFailure({ message: getError(e) }));
  }
}

function* archiveLender(action) {
  try {
    const { lenderId } = action.payload;
    yield call(API.axiosApi.delete, `/lenders/invite/${lenderId}`);
    yield put(archiveLenderSuccess({ lenderId }));
  } catch (e) {
    yield put(archiveLenderFailure({ message: getError(e) }));
  }
}

function* lenderResendInvitationRequest(action) {
  try {
    const { lenderId } = action.payload;
    yield call(API.axiosApi.post, `/auth/resend-invite-to-ld`, {
      lenders: [Number(lenderId)],
    });
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'Your invitation was resent',
      })
    );
    yield put(lenderResendInvitationSuccess());
  } catch (e) {
    yield put(lenderResendInvitationFailure({ message: getError(e) }));
  }
}

function* getUsers(action) {
  try {
    const { id } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/users/${id}/`);

    const userType = userTypeSelector(yield select());
    const isCurrentUserBB = userType.borrower || userType.broker;

    let tags;
    
    if (!isCurrentUserBB) {
      tags = yield call(API.axiosApi.get, '/client-tags/');
    }else{
      tags = yield call(API.axiosApi.get, '/lender-tags/');
    }
    const updatedTags = tags.data.response.map((tag) => ({ label: tag, value: tag }));

    yield put(
      getUsersSuccess({
        data: {
          ...response,
          tags: response.tags.map((tag) => ({ label: tag, value: tag })),
        },
        tags: updatedTags,
      })
    );
    
    
  } catch (e) {
    yield put(getUsersFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_USERS_REQUEST, getUsers),
  takeLatest(types.GET_LENDER_DETAILS_REQUEST, getDetails),
  takeLatest(types.GET_BB_DETAILS_REQUEST, getBbDetails),
  takeLatest(types.ADD_TAG_TO_LENDER_REQUEST, addTagToLender),
  takeLatest(types.REMOVE_TAG_FROM_LENDER_REQUEST, removeTagFromLender),
  takeLatest(types.ADD_NOTE_TO_LENDER_REQUEST, addNoteToLender),
  takeLatest(types.GET_LENDER_ACTIVITIES_REQUEST, getLenderActivities),
  takeLatest(types.ARCHIVE_LENDER_REQUEST, archiveLender),
  takeLatest(types.LENDER_RESEND_INVITATION_REQUEST, lenderResendInvitationRequest),

  takeLatest(types.ADD_TAG_TO_CLIENT_REQUEST, addTagToClient),
  takeLatest(types.REMOVE_TAG_FROM_CLIENT_REQUEST, removeTagFromClient),
  takeLatest(types.ADD_NOTE_TO_CLIENT_REQUEST, addNoteToClient),
];
