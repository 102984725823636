import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Alert from 'components/shared/Alert';
import { Modal } from 'ui/Modal';
import { connect } from 'react-redux';
import { socketRequest, getConfigurationRequest, getProfileRequest, settingDataRequest} from 'actions';
import { Spinner } from 'components/Spinner';
import { withRouter } from 'react-router';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { createGlobalStyle } from 'styled-components/macro';
// import { StripeProvider } from 'react-stripe-elements';
// import config from 'constants/config';
import { NotificationControl } from 'components/NotificationControl';
import { normalize } from 'styles/normalize';
import { NotFound } from 'features/NotFound';

const DashboardWrapper = lazy(() =>
  import(/* webpackChunkName: "DashboardWrapper" */ 'containers/DashboardWrapper')
);

const StandaloneLoan = lazy(() =>
  import(/* webpackChunkName: "StandaloneLoan" */ 'features/StandaloneLoan')
);

const StandAloneListing = lazy(() =>
  import(/* webpackChunkName: "StandAloneListing" */ 'features/StandAloneListing')
);

const Print = lazy(() =>
  import(/* webpackChunkName: "LoanPrint" */ 'features/LoanPrint')
);

const Auth = lazy(() => import(/* webpackChunkName: "Auth" */ 'containers/Auth'));

const ConfidentialityAgreementPrint = lazy(() =>
  import(
    /* webpackChunkName: "ConfidentialityAgreementPrint" */ 'features/ConfidentialityAgreementPrint'
  )
);
const FinishRegistration = lazy(() =>
  import(/* webpackChunkName: "FinishRegistration" */ 'features/FinishRegistration')
);
const OAuth = lazy(() =>
  import(/* webpackChunkName: "FinishRegistration" */ 'features/OAuth')
);

class App extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(settingDataRequest());
    dispatch(getConfigurationRequest());
    dispatch(getProfileRequest());
    dispatch(socketRequest());
  }

  render() {
    const { isFetched } = this.props;
    return (
      <ErrorBoundary>
        {/*<StripeProvider apiKey={config.STRIPE_KEY}>*/}
        <GlobalStyle />
        <Spinner />
        <Alert />
        <NotificationControl />
        <Modal />
        {isFetched && (
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                path="/dashboard"
                render={(props) => <DashboardWrapper {...props} />}
              />

              <Route
                exact
                path="/property-details/:id/print"
                render={(props) => <Print {...props} />}
              />

              <Route
                exact
                path="/property-details/:id?"
                render={(props) => <StandaloneLoan {...props} />}
              />

              <Route
                exact
                path="/listing/:id/:action?"
                render={(props) => <StandAloneListing {...props} />}
              />

              <Route
                exact
                path="/confidentiality-agreement"
                render={(props) => <ConfidentialityAgreementPrint {...props} />}
              />

              <Route
                exact
                path="/finish-registration"
                render={(props) => <FinishRegistration {...props} />}
              />
              <Route exact path="/authorize" render={(props) => <OAuth {...props} />} />

              <Route path="/404" render={(props) => <NotFound />} />

              <Route path="/" render={(props) => <Auth {...props} />} />
            </Switch>
          </Suspense>
        )}
        {/*</StripeProvider>*/}
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state, props) {
  const { currentUser } = state;

  return {
    isFetched: currentUser.isFetched,
    isLogged: currentUser.id,
  };
}

export default withRouter(connect(mapStateToProps)(App));

const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    font-size: 14px;
    font-family: 'Muli', 'Arial', sans-serif;
    min-height: 100%;
    height:100%;
    background: #fafafb;
  }

  #root{
    height:100%;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;

    @media (max-width: 768px) {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: #1fbbfd;
    text-decoration:none;
  }

  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(152,164,171,16);
    border: none;
    transform: translate(-50%, -50%);
    max-width: 920px;
    max-height: 100%; //80%
    width: 100%;
    margin-right: -50%;
    padding: 0;
    background: #FAFAFB;
    overflow-y: auto;
    outline: none;

    display: flex;
    flex-direction:column;

    &.sm {
      max-width: 540px;
    }

    &.md {
      max-width: 680px;
    }

    &.lg {
      max-width: 1005px;
    }

    &.overflowInit{
      overflow: initial;
    }
    &.overflowHidden{
      overflow: hidden;
    }

    &.defTopPosition{
      top: 120px;
    }
    &.isSearchTopPosition{
      top: 160px;
    }
    &.noMatchesTopPosition{
      top: 70px;
    }

    &.withExpand {
      @media (max-width: 768px) {
        transform: unset;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 0;
        transform: unset;
        max-width: unset;
        max-height: unset;
      }
    }
  }

  .Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(43, 43, 43, 0.35);
    z-index: 999;
  }
`;
