import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { map, filter, keys } from 'ramda';

import { push } from 'connected-react-router';
import { decamelizeKeys, decamelize } from 'humps';
import { OTHER_PROPERTY_TYPE, BORROWER_TEAM_MEMBER_TYPES } from 'constants/appRelated';
import { showModal } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { getError } from 'sagas/utils';
import { wordsRemove } from 'lib/strings';

import {
  getLendingCriteriaSuccess,
  getLendingCriteriaFailure,
  changeLendingCriteriaSuccess,
  changeLendingCriteriaFailure,
  getUserInfoSuccess,
  getUserInfoFailure,
  changeUserInfoSuccess,
  changeUserInfoFailure,
  borrowerAddNewMemberSuccess,
  borrowerAddNewMemberFailure,
  removeBorrowerMemberSuccess,
  removeBorrowerMemberFailure,
  changeBorrowerMemberSuccess,
  changeBorrowerMemberFailure,
  resendTeamMemberInvitationSuccess,
  resendTeamMemberInvitationFailure,
  getUserProfileSuccess,
  getUserProfileFailure,
  changeTeamDataSuccess,
  changeTeamDataFailure,
  removeTeamLogoSuccess,
  removeTeamLogoFailure,
  changePasswordSuccess,
  changePasswordFailure,
  removeLendingCriteriaBrochureSuccess,
  removeLendingCriteriaBrochureFailure,
  getInstalledAppsSuccess,
  getInstalledAppsFailure,
  removeInstalledAppSuccess,
  removeInstalledAppFailure,
} from './actions';

function* getLendingCriteria() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, 'auth/lending-criteria');

    yield put(getLendingCriteriaSuccess(response));
  } catch (e) {
    yield put(getLendingCriteriaFailure({ message: getError(e) }));
  }
}

function* changeLendingCriteria(action) {
  try {
    const {
      values: {
        assetLocations,
        assetLocationAllUsa,
        sponsorLocations,
        sponsorLocationAllUsa,
        loanTypes,
        propertyTypes,
        loanTermMin,
        loanTermMinUnit,
        loanTermMax,
        loanTermMaxUnit,
        amountMax,
        amountMin,
        nonRecourse,
        ltvRangeMin,
        ltvRangeMax,
        ltcRangeMin,
        ltcRangeMax,
        minimumDscr,
        maximumDscr,
        sizeOfCrePortfolio,
        type,
        purposes,
        otherPurposes,
        otherLoanTypes,
        otherPropertyTypes,
        onlyOwnerOccupied,
      },
      brochure,
    } = action.payload;

    const loanTermMinCalc = loanTermMin * (loanTermMinUnit.value ? 12 : 1);
    const loanTermMaxCalc = loanTermMax * (loanTermMaxUnit.value ? 12 : 1);

    const request = {
      assetLocations,
      assetLocationAllUsa: !!assetLocationAllUsa,
      sponsorLocations,
      sponsorLocationAllUsa: !!sponsorLocationAllUsa,
      loanTypes: loanTypes.map((item) => item.value),
      propertyTypes: propertyTypes
        .map((item) => item.value)
        .filter((value) => value !== OTHER_PROPERTY_TYPE),
      loanTerms: {
        lower: loanTermMinCalc > loanTermMaxCalc ? loanTermMaxCalc : loanTermMinCalc,
        upper: loanTermMinCalc > loanTermMaxCalc ? loanTermMinCalc : loanTermMaxCalc,
      },
      amount: {
        upper: amountMax ? wordsRemove(amountMax) : null,
        lower: amountMin ? wordsRemove(amountMin) : null,
      },
      nonRecourse: !nonRecourse,
      ltvRange: { lower: wordsRemove(ltvRangeMin), upper: wordsRemove(ltvRangeMax) },
      ltcRange: { lower: wordsRemove(ltcRangeMin), upper: wordsRemove(ltcRangeMax) },
      dscrRange: { lower: minimumDscr, upper: maximumDscr },
      sizeOfCrePortfolio: sizeOfCrePortfolio.value,
      type: type.value,
      purposes: purposes.map((item) => item.value),
      otherPurposes,
      otherLoanTypes,
      otherPropertyTypes,
      onlyOwnerOccupied: onlyOwnerOccupied ? 1 : 0,
    };

    const formData = new FormData();

    keys(request).forEach((key) => {
      if (
        key === 'loanTerms' ||
        key === 'amount' ||
        key === 'ltvRange' ||
        key === 'ltcRange' ||
        key === 'dscrRange'
      ) {
        formData.append(`${decamelize(key)}[lower]`, request[key].lower);
        formData.append(`${decamelize(key)}[upper]`, request[key].upper);
      } else {
        formData.append(decamelize(key), request[key]);
      }
    });

    if (brochure) {
      formData.append('brochure', brochure, brochure.name);
    }

    const {
      data: { response },
    } = yield call(API.axiosApi.put, 'auth/lending-criteria', formData);

    yield put(changeLendingCriteriaSuccess({ lendingCriteria: response }));
    yield put(push('/dashboard'));
  } catch (e) {
    yield put(changeLendingCriteriaFailure({ message: getError(e) }));
  }
}

function* getProfile() {
  try {
    const profileResponse = yield call(API.axiosApi.get, 'auth/profile');

    const profile = profileResponse.data.response;
    yield put(getUserProfileSuccess({ ...profile }));
  } catch (e) {
    yield put(getUserProfileFailure({ message: getError(e) }));
  }
}

// user profile

function* getUserInfo() {
  try {
    const notificationResponse = yield call(API.axiosApi.get, 'notification-settings');

    const notificationData = notificationResponse.data.response;
    yield put(
      getUserInfoSuccess({
        notificationInfo: notificationData,
      })
    );
  } catch (e) {
    yield put(getUserInfoFailure({ message: getError(e) }));
  }
}

function* changeUserInfo(action) {
  try {
    const { notifications, user } = action.payload;

    if (notifications) {
      const { webNotifications, emailNotifications, sms } = map(
        (item) => item || false,
        notifications
      );
      yield call(API.axiosApi.put, 'notification-settings', {
        push: webNotifications,
        email: emailNotifications,
        sms,
      });
      yield put(changeUserInfoSuccess());
    }

    if (user) {
      const userRequest = filter(Boolean, user);
      userRequest.phone = user.phone ? wordsRemove(user.phone) : null;
      userRequest.mobilePhone = user.mobilePhone ? wordsRemove(user.mobilePhone) : null;

      const userResponse = yield call(API.axiosApi.put, 'auth/customer-profile', {
        ...decamelizeKeys(userRequest),
      });

      const userData = userResponse.data.response;

      yield put(changeUserInfoSuccess(userData));
    }

    yield put(push('/dashboard'));
  } catch (e) {
    yield put(changeUserInfoFailure({ message: getError(e) }));
  }
}

function* addMember(action) {
  const { form } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/auth/team/', {
      ...decamelizeKeys({
        ...form,
        permissionsLevel: form.canNegotiate
          ? BORROWER_TEAM_MEMBER_TYPES.CAN_NEGOTIATE
          : BORROWER_TEAM_MEMBER_TYPES.USER,
      }),
    });
    yield put(
      borrowerAddNewMemberSuccess({
        user: { ...response, firstName: form.firstName, lastName: form.lastName },
      })
    );
  } catch (e) {
    yield put(borrowerAddNewMemberFailure({ message: getError(e) }));
  }
}

function* removeMember(action) {
  const { userId } = action.payload;

  try {
    yield call(API.axiosApi.delete, `/auth/team/${userId}`, {});
    yield put(removeBorrowerMemberSuccess({ userId }));
  } catch (e) {
    yield put(removeBorrowerMemberFailure({ message: getError(e) }));
  }
}

function* changeMemberPermissions(action) {
  const { userId, canNegotiate } = action.payload;

  try {
    const permissionsLevel = canNegotiate
      ? BORROWER_TEAM_MEMBER_TYPES.CAN_NEGOTIATE
      : BORROWER_TEAM_MEMBER_TYPES.USER;

    const {
      data: { response },
    } = yield call(API.axiosApi.put, `/auth/team/${userId}`, {
      permissions_level: permissionsLevel,
    });

    yield put(
      changeBorrowerMemberSuccess({ userId, permissionsLevel, userData: response })
    );
  } catch (e) {
    yield put(changeBorrowerMemberFailure({ message: getError(e) }));
  }
}
function* resendInvitation(action) {
  const { id } = action.payload;

  try {
    yield call(API.axiosApi.post, `/auth/team/${id}/resend-invite/`);

    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'You have successfully resent the invitation',
      })
    );
    yield put(resendTeamMemberInvitationSuccess());
  } catch (e) {
    yield put(resendTeamMemberInvitationFailure({ message: getError(e) }));
  }
}

function* changeTeamData(action) {
  const { data, logo } = action.payload;
  try {
    if (data) {
      const {
        data: { response },
      } = yield call(API.axiosApi.patch, '/auth/team-settings', decamelizeKeys(data));

      yield put(changeTeamDataSuccess({ response }));
    }
    if (logo) {
      const {
        data: { response },
      } = yield call(API.axiosApi.patch, '/auth/team-settings/logo', logo);

      yield put(changeTeamDataSuccess({ response, logoUpdate: true }));
    }
  } catch (e) {
    yield put(changeTeamDataFailure({ message: getError(e) }));
  }
}

function* removeTeamLogo() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.delete, '/auth/team-settings/logo');

    yield put(removeTeamLogoSuccess({ response }));
  } catch (e) {
    yield put(removeTeamLogoFailure({ message: getError(e) }));
  }
}

function* changePassword(action) {
  const { values } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.put, '/auth/change-password', decamelizeKeys(values));

    yield put(changePasswordSuccess({ response }));
    yield put(push('/dashboard'));
  } catch (e) {
    yield put(changePasswordFailure({ message: getError(e) }));
  }
}

function* removeLendingCriteriaBrochure() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.patch, 'auth/lending-criteria', { brochure: null });
    yield put(removeLendingCriteriaBrochureSuccess({ ...response }));
  } catch (e) {
    console.log(e, 1234);
    yield put(removeLendingCriteriaBrochureFailure({ message: getError(e) }));
  }
}

function* getInstalledApps() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/installed-applications/');
    console.log(response);
    yield put(getInstalledAppsSuccess({ appsList: response }));
  } catch (e) {
    yield put(getInstalledAppsFailure({ message: getError(e) }));
  }
}

function* removeInstalledApp(action) {
  try {
    const { id } = action.payload;
    yield call(API.axiosApi.delete, `/auth/installed-applications/${id}/`);
    yield put(removeInstalledAppSuccess({ id }));
  } catch (e) {
    yield put(removeInstalledAppFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_LENDING_CRITERIA_REQUEST, getLendingCriteria),
  takeLatest(types.CHANGE_LENDING_CRITERIA_REQUEST, changeLendingCriteria),
  takeLatest(types.GET_USER_PROFILE_REQUEST, getProfile),

  takeLatest(types.GET_USER_INFO_REQUEST, getUserInfo),
  takeLatest(types.CHANGE_USER_INFO_REQUEST, changeUserInfo),
  takeLatest(types.BORROWER_ADD_NEW_MEMBER_REQUEST, addMember),
  takeLatest(types.BORROWER_REMOVE_MEMBER_REQUEST, removeMember),
  takeLatest(types.CHANGE_BORROWER_MEMBER_REQUEST, changeMemberPermissions),
  takeLatest(types.RESEND_TEAM_MEMBER_INVITATION_REQUEST, resendInvitation),
  takeLatest(types.CHANGE_TEAM_DATA_REQUEST, changeTeamData),
  takeLatest(types.REMOVE_TEAM_LOGO_REQUEST, removeTeamLogo),
  takeLatest(types.CHANGE_PASSWORD_REQUEST, changePassword),
  takeLatest(
    types.REMOVE_LENDING_CRITERIA_BROCHURE_REQUEST,
    removeLendingCriteriaBrochure
  ),
  takeLatest(types.GET_INSTALLED_APPS_REQUEST, getInstalledApps),
  takeLatest(types.REMOVE_INSTALLED_APP_REQUEST, removeInstalledApp),
];
