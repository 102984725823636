import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import {
  getSwitchToModalSuccess,
  getSwitchToModalFailure,
  showModal,
  switchToMemberViewSuccess,
  switchToMemberViewFailure,
  manualOfferModalSuccess,
  manualOfferModalFailure,
  loanDealWonModalSuccess,
  loanDealWonModalFailure,
  getFieldHistorySuccess,
  getFieldHistoryFailure,
  manualTermsheetModalSuccess,
  manualTermsheetModalFailure,
  switchToSearchSuccess,
  switchToSearchFailure,
} from 'actions/modalActions';
import { decamelize } from 'humps';
import { modals } from 'constants/modalTypes';
import { getError } from 'sagas/utils';
import { analytics } from 'lib/analytics';
import { TERMSHEET_EVENTS, TRACK } from 'constants/appRelated';
import { LENDER } from 'constants/appRelated';

function filterForTeams(response, viewedAs, accountType) {
  let filteredTeam;

  if(accountType === LENDER){
    filteredTeam = response.map((team) => {
      const updatedMembers = team.members.filter(
        (member) => member.userId !== viewedAs && member.joined
      );
      //isMyTeam = team.members.find(member => member.userId == viewedAs) && team.id;

      return {...team, members: updatedMembers}
    });
    return filteredTeam = filteredTeam.filter((team) => team.members.length)
  }else{
   return filteredTeam = response.filter(
      (member) => member.userId !== viewedAs && member.joined
    );
  }
}

function* getSwitchToModal() {
  try {
    const state = yield select();
    const { accountType, id, viewedAs} = state.currentUser;
    
    let isMyTeam;
    
    const {
      data: { response },
    } = yield call(API.axiosApi.get, accountType === LENDER ? 'auth/company/team/' : '/auth/team');

    let filteredTeam = filterForTeams(response, viewedAs, accountType);
    
    yield put(
      showModal(modals.SWITCH_TO, {
       // overflow: 'overflowHidden',
        isMyTeam, 
        size: 'md',
        filteredTeam,
        currentUserId: id,
        viewedAs,
        accountType
      })
    );

    yield put(getSwitchToModalSuccess());
  } catch (e) {
    console.error(e);
    yield put(getSwitchToModalFailure({ message: getError(e) }));
  }
}

function* switchToSearch(action) {
  try {
    const state = yield select();
    const { accountType, viewedAs } = state.currentUser;

    const {
      data: { response },
    } = yield call(API.axiosApi.get, 
      accountType === LENDER ? 
      `auth/company/team/?search=${action.payload}`
       :
      `/auth/team/?search=${action.payload}`);
   
    let filteredTeam = filterForTeams(response, viewedAs, accountType);

    yield put(switchToSearchSuccess(filteredTeam));
  } 
  catch (e) {
   console.error(e);
   yield put(switchToSearchFailure({ message: getError(e) }));
  }
}

function* switchToMemberView(action) {
  try {
    const { myTeamView, id, type } = action.payload;
    let data = {
      viewed_as_team: null, 
      viewed_as: null
    }
  
    data = type === 'teamId' ?
      {...data, viewed_as_team: myTeamView ? null : id} : 
      {...data, viewed_as: id}

    yield call(API.axiosApi.patch, '/auth/customer-profile', {...data});
    yield put(switchToMemberViewSuccess());

    window.location.reload();
  } catch (e) {
    console.error(e);
    yield put(switchToMemberViewFailure({ message: getError(e) }));
  }
}

function* manualOfferModalRequest(action) {
  try {
    const { loanId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      'lenders/invite?no_page=true&type_of_capital_source=debt,debtequity'
    );

    const loanResponse = yield call(API.axiosApi.get, `/loans/${loanId}`);

    yield put(
      showModal(modals.MANUAL_OFFER_MODAL, {
        loan: loanResponse.data.response,
        invitedLenders: response.results,
      })
    );
    yield put(manualOfferModalSuccess());
  } catch (e) {
    yield put(manualOfferModalFailure({ message: getError(e) }));
  }
}

function* loanDealWonModalRequest(action) {
  try {
    const { loanId, userType, loan } = action.payload;
    let response = [];
    if(!userType?.lender){
      response = yield call(
        API.axiosApi.get,
        'lenders/invite?no_page=true&type_of_capital_source=debt,debtequity'
      );
    }
    yield put(showModal(modals.LOAN_DEAL_WON_FORM, {
      invitedLenders: response.data?.response?.results,
      userType,
      loanId,
      size: 'sm', 
      loan
    }));
    
    yield put(loanDealWonModalSuccess());
  } catch (e) {
    yield put(loanDealWonModalFailure({ message: getError(e) }));
  }
}

function* getFieldHistoryModal(action) {
  try {
    const { fieldName, sponsorProfileField } = action.payload;
    const {
      loanNew: {
        id,
        data: { sponsorProfile, uuid },
      },
    } = yield select();
    let data = null;

    if (sponsorProfileField) {
      data = yield call(
        API.axiosApi.get,
        `/sponsor-history/${sponsorProfile.id}?field_name=${decamelize(fieldName)}`
      );
    } else {
      data = yield call(
        API.axiosApi.get,
        `/loans/${uuid}/field-history?field_name=${decamelize(fieldName)}`
      );
    }

    yield put(
      showModal(modals.FIELD_HISTORY, {
        size: 'sm',
        fieldHistory: data.data.response,
      })
    );
    yield put(getFieldHistorySuccess());
  } catch (e) {
    yield put(getFieldHistoryFailure({ message: getError(e) }));
  }
}

function* manualTermsheetModalRequest(action) {
  try {
    const { termsheetId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      'lenders/invite?no_page=true&type_of_capital_source=debt,debtequity'
    );
    if (termsheetId) {
      analytics(TRACK, TERMSHEET_EVENTS.LOAN_TERM_SHEET_ADD_MANUAL);
    } else {
      analytics(TRACK, TERMSHEET_EVENTS.LOAN_TERM_SHEET_EDIT);
    }
    yield put(
      showModal(modals.MANUAL_TERMSHEET_MODAL, {
        invitedLenders: response.results,
        size: 'md',
        termsheetId,
        ...action.payload,
      })
    );
    yield put(manualTermsheetModalSuccess());
  } catch (e) {
    yield put(manualTermsheetModalFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_SWITCH_TO_MODAL_REQUEST, getSwitchToModal),
  takeLatest(types.SWITCH_TO_MEMBER_VIEW_REQUEST, switchToMemberView),
  takeLatest(types.SWITCH_TO_SEARCH_REQUEST, switchToSearch),

  takeLatest(types.MANUAL_OFFER_MODAL_REQUEST, manualOfferModalRequest),
  takeLatest(types.LOAN_DEAL_WON_MODAL_REQUEST, loanDealWonModalRequest),
  takeLatest(types.GET_FIELD_HISTORY_MODAL_REQUEST, getFieldHistoryModal),
  takeLatest(types.MANUAL_TERMSHEET_MODAL_REQUEST, manualTermsheetModalRequest),
];
