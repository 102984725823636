import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Calendar from 'containers/MyTasksWrapper/components/SyncTasks/Calendar'
import GoogleIcon from 'icons/ico_calendar_google.svg';
import OfficeIcon from 'icons/ico_calendar_office.svg';
import { connect } from 'react-redux';
import { getAuthCalendarsRequest, getCalendarsRequest} from '../../actions';
import GoogleButton from 'react-google-button'
import { createLoadingSelector, getRoughRequestAction } from 'lib/state';
import { hideCalendarBannerRequest } from '../../actions';
import { Spinner } from 'components/Spinner';

const MyCalendars = ({
  isFetching,
  googleCalendarList,
  hideModal,
  getAuthCalendars,
  initConnected,
  getCalendars,
  googleCalendarEnabled,
  microsoftCalendarEnabled,
  googleInfo,
  microsoftInfo,
  calendarsSaved,
  hideCalendarBanner,
}) => {

  useEffect(() => {
    if(googleCalendarEnabled) getCalendars({type: 'google'});

    //if(microsoftCalendarEnabled) getCalendars({type: 'microsoft'});
  }, [calendarsSaved]);

  const handleAuthCalendars = (type) => {
    getAuthCalendars({type});
    hideCalendarBanner();
  }

  if(isFetching) {
    return <Spinner show defStyles = {{postiton: true, width: true, height: true}} />;
  }
  
  return (
    <>
      {!initConnected?.microsoft &&
        <Calendar
          isFetching={isFetching}
          isInitCalendar={initConnected?.google}
          isConnect={googleCalendarEnabled}
          type={'google'}
          text={'Google Calendar'} 
          img={GoogleIcon}
          hideModal={hideModal}
          calendarData={{
            email: googleInfo?.email,
            calendarList: googleCalendarList.calendars,
            chosenCalendares: googleCalendarList.chosenCalendares,
          }}
        >
          <GoogleButtonWrap>
            <GoogleButton onClick={() => handleAuthCalendars('google')}/>
          </GoogleButtonWrap>
        </Calendar>
      }

      {!initConnected?.google &&
        <Calendar
          isFetching={isFetching}
          isInitCalendar={initConnected?.microsoft}
          isConnect={microsoftCalendarEnabled}
          type={'microsoft'}
          text={'Outlook Office 365'}
          img={OfficeIcon}
          hideModal={hideModal}
          calendarData={{
            email: microsoftInfo?.email
          }}
          
        >
          <button onClick={() => handleAuthCalendars('microsoft')}>
            connect
          </button>
        </Calendar>
      }
    </>
  )
}

const loadingSelector = createLoadingSelector([
  getRoughRequestAction(getCalendarsRequest().type),
  getRoughRequestAction(getAuthCalendarsRequest().type),
]);


const mapStateToProps = (state) => ({
  googleInfo: state.currentUser.googleInfo,
  microsoftInfo: state.currentUser.microsoftInfo,
  googleCalendarEnabled: state.currentUser.googleCalendarEnabled,
  microsoftCalendarEnabled: state.currentUser.microsoftCalendarEnabled,
  googleCalendarList: state.myTasks.googleCalendarList,
  calendarsSaved: state.myTasks.calendarsSaved,
  isFetching: loadingSelector(state),
})

const mapDispatchToProps = {
  getAuthCalendars: getAuthCalendarsRequest,
  getCalendars: getCalendarsRequest,
  hideCalendarBanner: hideCalendarBannerRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendars);


const GoogleButtonWrap = styled.div`
  @media (max-width: 550px) {
    width: 50%;
    span{
      font-size: 14px;
    }
  }

  @media (max-width: 440px) {
    width: 45%;
  }
`;
