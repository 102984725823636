import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Text } from 'ui/Text';
import { CheckCircle as CheckUiComponent } from 'ui/CheckCircle';
import { FolderIcon } from 'components/SvgIcons';

export const FolderCheckItem = ({
  label,
  status,
  input: { name, value, checked, ...restInput },
  ...custom
}) => (
  <FolderWrapper status={status} data-testid="folderCheckbox">
    <Label>
      <FolderIcon />
      {label}
    </Label>
    {status === 'available' && (
      <React.Fragment>
        <input type="checkbox" name={name} checked={checked} {...custom} {...restInput} />
        <CheckUiComponent variant={checked && 'blue'} />
      </React.Fragment>
    )}
    {status === 'pending' && (
      <img
        width="18px"
        height="18px"
        src="/images/icons/ico_pending_small.svg"
        alt="icon"
      />
    )}
  </FolderWrapper>
);

const FolderWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    position: absolute;
    opacity: 0;
    left: -10px;
  }

  ${(p) =>
    p.status === 'available' &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;

        & > div {
          background-color: #4394ea;
        }
      }
    `}
`;

const Label = styled(Text)`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 18px;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;
