import types from 'constants/actionTypes';

export const getLenderDetailsRequest = (id, additionalFunctionality) => ({
  type: types.GET_LENDER_DETAILS_REQUEST,
  payload: { id, additionalFunctionality },
});

export const getUsersRequest = (payload) => ({
  type: types.GET_USERS_REQUEST,
  payload,
});

export const getUsersSuccess = (payload) => ({
  type: types.GET_USERS_SUCCESS,
  payload,
});

export const getUsersFailure = (payload) => ({
  type: types.GET_USERS_FAILURE,
  payload,
});

export const getLenderDetailsSuccess = (payload) => ({
  type: types.GET_LENDER_DETAILS_SUCCESS,
  payload,
});
export const getLenderDetailsFailure = (payload = {}) => ({
  type: types.GET_LENDER_DETAILS_FAILURE,
  payload,
});

export const clearLenderDetails = () => ({
  type: types.CLEAR_LENDER_DETAILS,
  payload: {},
});

export const addTagToLenderRequest = (payload = {}) => ({
  type: types.ADD_TAG_TO_LENDER_REQUEST,
  payload,
});
export const addTagToLenderSuccess = (payload = {}) => ({
  type: types.ADD_TAG_TO_LENDER_SUCCESS,
  payload,
});
export const addTagToLenderFailure = (payload = {}) => ({
  type: types.ADD_TAG_TO_LENDER_FAILURE,
  payload,
});

export const removeTagFromLenderRequest = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_LENDER_REQUEST,
  payload,
});
export const removeTagFromLenderSuccess = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_LENDER_SUCCESS,
  payload,
});
export const removeTagFromLenderFailure = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_LENDER_FAILURE,
  payload,
});

export const addNoteToLenderRequest = (payload = {}) => ({
  type: types.ADD_NOTE_TO_LENDER_REQUEST,
  payload,
});
export const addNoteToLenderSuccess = (payload = {}) => ({
  type: types.ADD_NOTE_TO_LENDER_SUCCESS,
  payload,
});
export const addNoteToLenderFailure = (payload = {}) => ({
  type: types.ADD_NOTE_TO_LENDER_FAILURE,
  payload,
});

export const getLenderActivitiesRequest = ({ lenderId, page, filter }) => ({
  type: types.GET_LENDER_ACTIVITIES_REQUEST,
  payload: { lenderId, page, filter },
});
export const getLenderActivitiesSuccess = (payload = {}) => ({
  type: types.GET_LENDER_ACTIVITIES_SUCCESS,
  payload,
});
export const getLenderActivitiesFailure = (payload = {}) => ({
  type: types.GET_LENDER_ACTIVITIES_FAILURE,
  payload,
});

export const archiveLenderRequest = ({ lenderId }) => ({
  type: types.ARCHIVE_LENDER_REQUEST,
  payload: { lenderId },
});
export const archiveLenderSuccess = ({ lenderId }) => ({
  type: types.ARCHIVE_LENDER_SUCCESS,
  payload: { lenderId },
});
export const archiveLenderFailure = (payload = {}) => ({
  type: types.ARCHIVE_LENDER_FAILURE,
  payload,
});

export const getBbDetailsRequest = (id, additionalFunctionality) => ({
  type: types.GET_BB_DETAILS_REQUEST,
  payload: { id, additionalFunctionality },
});

export const getBbDetailsSuccess = (payload) => ({
  type: types.GET_BB_DETAILS_SUCCESS,
  payload,
});
export const getBbDetailsFailure = (payload = {}) => ({
  type: types.GET_BB_DETAILS_FAILURE,
  payload,
});

export const lenderResendInvitationRequest = (payload = {}) => ({
  type: types.LENDER_RESEND_INVITATION_REQUEST,
  payload,
});
export const lenderResendInvitationSuccess = (payload = {}) => ({
  type: types.LENDER_RESEND_INVITATION_SUCCESS,
  payload,
});
export const lenderResendInvitationFailure = (payload = {}) => ({
  type: types.LENDER_RESEND_INVITATION_FAILURE,
  payload,
});

export const addTagToClientRequest = (payload = {}) => ({
  type: types.ADD_TAG_TO_CLIENT_REQUEST,
  payload,
});
export const addTagToClientSuccess = (payload = {}) => ({
  type: types.ADD_TAG_TO_CLIENT_SUCCESS,
  payload,
});
export const addTagToClientFailure = (payload = {}) => ({
  type: types.ADD_TAG_TO_CLIENT_FAILURE,
  payload,
});

export const removeTagFromClientRequest = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_CLIENT_REQUEST,
  payload,
});
export const removeTagFromClientSuccess = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_CLIENT_SUCCESS,
  payload,
});
export const removeTagFromClientFailure = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_CLIENT_FAILURE,
  payload,
});

export const addNoteToClientRequest = (payload = {}) => ({
  type: types.ADD_NOTE_TO_CLIENT_REQUEST,
  payload,
});
export const addNoteToClientSuccess = (payload = {}) => ({
  type: types.ADD_NOTE_TO_CLIENT_SUCCESS,
  payload,
});
export const addNoteToClientFailure = (payload = {}) => ({
  type: types.ADD_NOTE_TO_CLIENT_FAILURE,
  payload,
});
