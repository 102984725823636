import { put, call, takeLatest, takeEvery, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import {
  analytics,
  intercomIdentify,
  isTokenSentToServer,
  setTokenSentToServer,
} from 'lib/analytics';
import * as API from 'api';
import { push } from 'connected-react-router';
import { map } from 'ramda';
import { decamelizeKeys } from 'humps';
import firebase from 'firebase/app';
import 'firebase/messaging';
import {
  logoutSuccess,
  logoutFailure,
  emailExistsSuccess,
  emailExistsFailure,
  postInboxAnswerSuccess,
  postInboxAnswerFailure,
  socketRequest,
  setPasswordSuccess,
  setPasswordFailure,
  confirmEmailSuccess,
  confirmEmailFailure,
  changeUserProfileSuccess,
  changeUserProfileFailure,
  enterpriseLenderRegistrationSuccess,
  enterpriseLenderRegistrationFailure,
  deleteCredentialsRequest,
  requestPermissionSuccess,
  requestPermissionFailure,
  shareOfferSuccess,
  shareOfferFailure,
  listingLoginSuccess,
  listingLoginFailure,
  getContactsSuccess,
  getContactsFailure,
  getContactsTagsSuccess,
  getContactsTagsFailure,
  getContactsTypesSuccess,
  getContactsTypesFailure,
  backToLoginSuccess,
  backToLoginFailure,
} from 'actions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';

import { showModal, hideModal } from 'actions/modalActions';

import { inboxRequest, declineQuestionRequest } from 'features/Inbox/actions';
import { supportSuccess, supportFailure } from 'features/Profile/actions';
import { myOffersRequest } from 'features/Offers/actions';
import { loginSuccess, loginFailure } from 'features/Login/actions';
import {
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from 'features/PasswordReset/actions';
import {
  resendInviteSuccess,
  resendInviteFailure,
  registrationSuccess,
  registrationFailure,
} from 'features/Registration/actions';
import { passwordResetSuccess, passwordResetFailure } from 'features/SetPassword/actions';
import {
  listingRegistrationSuccess,
  listingRegistrationFailure,
} from 'features/RegistrationListing/actions';
import { IDENTIFY, LENDER, BORROWER, BROKER, EMAIL_STATUS } from 'constants/appRelated';
import { getIntercomSettings, getError } from 'sagas/utils';
import { getLenderCompanyDataRequest, getLenderTeamMembersRequest } from 'features/LenderCompany/actions';
import { makeUrlFromObject } from 'lib/url';
import { setSession } from '../utils/sessionStorage';

function* registration(action) {
  const { values, loanId } = action.payload;
  const {
    accountType,
    email,
    password,
    first_name,
    last_name,
    business_name,
    business_url,
    phone,
    mobilePhone,
    preferred_timezone,
    source,
    entityType,
    llc,
    title,
    source_other,
    phone_ext,
  } = values;

  try {
    // TODO : recieve data, not args
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/register', {
      account_type: accountType,
      email,
      password,
      first_name,
      last_name,
      business_name,
      business_url: business_url && business_url.trim(),
      preferred_timezone: preferred_timezone ? preferred_timezone.value : undefined,
      phone: phone ? phone.replace(/[^0-9]/g, '') : undefined,
      mobile_phone: mobilePhone.replace(/[^0-9]/g, ''),
      source: source ? source.value : undefined,
      entity_type: entityType ? entityType.value : undefined,
      llc,
      title,
      source_other,
      phone_ext,
    });
    const { profile } = response;
    let teamData = {};

    if (profile.accountType === BROKER || profile.accountType === BORROWER) {
      const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');
      teamData = teamDataRequest.data.response;
    }

    if (profile.accountType === LENDER) {
      yield put(getLenderTeamMembersRequest());
      yield put(getLenderCompanyDataRequest());
    }

    yield put(registrationSuccess({ ...profile, team: teamData.members, teamData }));

    yield put(socketRequest());
    analytics(IDENTIFY, profile.id, { name: profile.firstName, email: profile.email });

    if (profile.accountType === LENDER) {
      yield put(push(`/finish-registration${loanId ? `?loanId=${loanId}` : ''}`));
    } else {
      setSession("prevPath", "registration");
      yield put(push(`/dashboard${loanId ? `?loanId=${loanId}` : ''}`));
    }

    intercomIdentify(getIntercomSettings(profile));
  } catch (e) {
    console.error(e);
    yield put(registrationFailure({ message: getError(e) }));
  }
}

function* verifyLender() {
  try {
    const { currentUser } = yield select();

    if (!currentUser.isVerified) {
      yield put(loginSuccess({ ...currentUser, isVerified: true }));
    }
  } catch (error) {
    console.error(error, 'error 1234');
  }
}

function* checkEmail(action) {
  const {
    email,
    reject,
    resolve,
    accountType,
    ignoreExistence,
    isInvited,
  } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/check_email', {
      email,
    });

    const data = { result: response && response.result };
    yield put(emailExistsSuccess(data));
    if (accountType === LENDER && data.result === EMAIL_STATUS.PERSONAL) {
      reject({ error: 'Please use work email address', status: data.result });
    } else if (data.result === EMAIL_STATUS.EXISTS && !ignoreExistence) {
      reject({ error: 'This e-mail is already used', status: data.result });
    } else if (data.result === EMAIL_STATUS.PENDING_INVITE) {
      if (isInvited) {
        resolve();
      } else {
        reject({ error: 'This e-mail is already used', status: data.result });
      }
    } else {
      resolve();
    }
  } catch (e) {
    console.error(e);
    yield put(emailExistsFailure());
    reject();
  }
}

function* support(action) {
  const { values } = action.payload;
  try {
    yield call(API.axiosApi.post, 'support', { ...values });

    yield put(supportSuccess());
    yield put(push('/dashboard'));
  } catch (e) {
    console.error(e);
    yield put(supportFailure());
  }
}

function* postAnswer(action) {
  const { answer, loanId, id, inbox, isPublic, afterSubmit } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `loans/${loanId}/questions/${id}`, {
      answer,
      is_public: isPublic,
    });

    yield put(
      postInboxAnswerSuccess({
        message: 'Successfully answered',
        notifications: response.unreadNotifications,
      })
    );

    switch (afterSubmit) {
      case 'fromOffers':
        yield put(myOffersRequest());
        break;

      default:
        yield put(inboxRequest({ inbox }));
        break;
    }
  } catch (e) {
    console.error(e, 'exeption');
    yield put(postInboxAnswerFailure({ message: getError(e) }));
  }
}

function* forgotPassword(action) {
  try {
    const email = action?.payload?.values?.email;

    yield call(API.axiosApi.post, 'auth/password/reset', { email });

    yield put(forgotPasswordSuccess());

    yield put(
      showModal(modals.RESET_PASSWORD_REQUEST_SUCCESS, {
        size: 'sm',
        email,
      })
    );
  } catch (e) {
    console.error(e, 'exeption');
    yield put(forgotPasswordFailure());
  }
}

function* resetPassword(action) {
  try {
    const { password, token, uid, loanId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/password/reset/confirm', {
      uid,
      token,
      new_password: password,
    });

    const { profile } = response;

    analytics(IDENTIFY, profile.id, { name: profile.firstName, email: profile.email });

    intercomIdentify(getIntercomSettings(profile));

    let teamData = {};

    if (profile.accountType === BROKER || profile.accountType === BORROWER) {
      const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');
      teamData = teamDataRequest.data.response;
    }

    if (profile.accountType === LENDER) {
      yield put(getLenderTeamMembersRequest());
      yield put(getLenderCompanyDataRequest());
    }

    yield put(passwordResetSuccess({ ...profile, team: teamData.members, teamData }));
    yield put(hideModal());

    if (profile.tfa?.secondStepRequired) {
      yield put(push('/confirm-auth'));
    } else {
      yield put(
        push(
          loanId && loanId !== 'undefined'
            ? `/dashboard/property-details/${loanId}`
            : '/dashboard'
        )
      );
    }

    yield put(socketRequest());
  } catch (e) {
    if (getError(e)[0] === 'User account is disabled.') {
      yield put(passwordResetFailure({}));
      yield put(
        showModal(modals.INFO_MODAL, {
          size: 'sm',
          infoType: INFO_MODAL_TYPE.WARNING,
          title: 'Your trial has ended',
          text:
            '<a href="https://app.hubspot.com/meetings/tyler534/discovery-call" target="_blank">Book a meeting</a> ' +
            'with our sales rep or contact our team via email at ' +
            '<a href="mailto:sales@realatom.com">sales@realatom.com</a>',
          buttonText: 'OK',
          disableClose: true,
        })
      );
    } else {
      yield put(passwordResetFailure({ message: getError(e) }));
    }
  }
}

function* setPassword(action) {
  try {
    const { password, token, uid } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/password/reset/confirm', {
      uid,
      token,
      new_password: password,
    });

    yield put(setPasswordSuccess(response));
    yield put(hideModal());

    yield put(push('/dashboard'));
    showModal(modals.INFO_MODAL, {
      size: 'sm',
      infoType: INFO_MODAL_TYPE.SUCCESS,
      title: 'Great job!',
      text:
        'Now that you created your password we will send your listing to RealAtom lenders for pre-qualification.',
    });

    const { profile } = response;

    analytics(IDENTIFY, profile.id, { name: profile.firstName, email: profile.email });

    yield put(socketRequest());
  } catch (e) {
    if (getError(e)[0] === 'User account is disabled.') {
      yield put(setPasswordFailure({}));
      yield put(
        showModal(modals.INFO_MODAL, {
          size: 'sm',
          infoType: INFO_MODAL_TYPE.WARNING,
          title: 'Your trial has ended',
          text:
            '<a href="https://app.hubspot.com/meetings/tyler534/discovery-call" target="_blank">Book a meeting</a> ' +
            'with our sales rep or contact our team via email at ' +
            '<a href="mailto:sales@realatom.com">sales@realatom.com</a>',
          buttonText: 'OK',
          disableClose: true,
        })
      );
    } else {
      yield put(setPasswordFailure({ message: getError(e) }));
    }
  }
}

function* changeProfile(action) {
  try {
    const { profile, withRedirect } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.patch, 'auth/profile', {
      ...decamelizeKeys(map((item) => item || null, profile)),
    });

    yield put(changeUserProfileSuccess(response));

    if (withRedirect) {
      yield put(push(withRedirect));
    }
  } catch (e) {
    yield put(changeUserProfileFailure({ message: getError(e) }));
  }
}

function* logout() {
  try {
    yield call(API.axiosApi.post, 'auth/logout');
    yield put(deleteCredentialsRequest());
    yield put(logoutSuccess());
    
    localStorage.removeItem('filter_marketplace_page');
    localStorage.removeItem('url_marketplace_page');

    localStorage.removeItem('filter_pipeline_page');
    localStorage.removeItem('url_pipeline_page');

    window.location.href = 'https://realatom.com';
  } catch (e) {
    console.log(e);
    yield put(logoutFailure({ message: getError(e) }));
  }
}
function* backToLogin() {
  try {
    yield call(API.axiosApi.post, 'auth/logout');
    yield put(deleteCredentialsRequest());
    yield put(backToLoginSuccess());
    yield put(push('/login'));
  } catch (e) {
    console.log(e);
    yield put(backToLoginFailure({ message: getError(e) }));
  }
}

function* confirmEmail(action) {
  const state = yield select();
  const { uid, token } = action.payload;

  try {
    yield call(API.axiosApi.post, 'auth/confirm-email/check', {
      uid,
      token,
    });

    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/confirm-email', {
      uid,
      token,
    });

    yield put(confirmEmailSuccess());

    if (!state.currentUser.isFetched) {
      yield put(
        loginSuccess({
          ...response.user,
          address1: response.address1,
          country: response.country,
          state: response.state,
          zip: response.zip,
          title: response.title,
          city: response.city,
          isNeedUpdateCriteria: response.isNeedUpdateCriteria,
          criteriaUpdatedAt: response.criteriaUpdatedAt,
        })
      );

      yield put(socketRequest());
    }

    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'Your Email has been successfully confirmed',
        buttonText: 'Go to dashboard',
        onButtonClick: (dispatch) => {
          dispatch(push('/dashboard'));
        },
      })
    );
  } catch (e) {
    console.log(e);
    const { result } = e.response.data.error;

    const isUserLogged = state.currentUser.id;
    if (result && result.length && result[0] === 'email_confirmed') {
      isUserLogged
        ? yield put(push('/dashboard'))
        : yield put(showModal(modals.EMAIL_ALREADY_CONFIRMED, {}));
    }

    yield put(confirmEmailFailure({ message: result[0] }));
  }
}

function* login(action) {
  const {
    values,
    loanId,
    afterLogin,
    redirectPath,
    inboxType,
    inboxId,
    oAuthParams,
  } = action.payload;
  const { email, password } = values;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/login', {
      email,
      password,
    });

    const { user, draftLoansCount, submittedLoansCount, postedLoansCount } = response;
    const userExpand = {
      ...user,
      draftLoansCount,
      submittedLoansCount,
      postedLoansCount,
    };

    let teamData = {};
    let companyData = [];

    if (
      (!user.tfa?.secondStepRequired && user.accountType === BROKER) ||
      user.accountType === BORROWER
    ) {
      const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');
      teamData = teamDataRequest.data.response;
    }

    if (user.accountType === LENDER) {
      yield put(getLenderTeamMembersRequest());
      yield put(getLenderCompanyDataRequest());
      
      const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-members');
      teamData = teamDataRequest.data.response;

      const companyDataRequest = yield call(API.axiosApi.get, '/auth/company');
      companyData = companyDataRequest.data.response;
    }

    let viewedAsData;

    if (user.viewedAs && teamData.members) {
      viewedAsData = teamData.members.filter(
        (member) => member.userId === user.viewedAs
      )[0];
    }

    yield put(
      loginSuccess({
        ...user,
        address1: response.address1,
        country: response.country,
        state: response.state,
        zip: response.zip,
        title: response.title,
        city: response.city,
        isNeedUpdateCriteria: response.isNeedUpdateCriteria,
        criteriaUpdatedAt: response.criteriaUpdatedAt,
        teamData,
        companyData: companyData.teams,
        team: teamData.members,
        viewedAsData,
      })
    );
    yield put(hideModal());

    analytics(IDENTIFY, user.id, { name: user.firstName, email: user.email });

    if (user.isPasswordTemporary) {
      yield put(
        showModal(modals.FORCE_PASSWORD_CHANGE, {
          disableClose: true,
          size: 'sm',
        })
      );
    }

    if (afterLogin) {
      switch (afterLogin) {
        case 'toInbox':
          if (inboxType === 'decline') {
            yield put(declineQuestionRequest({ pk: inboxId }));
            yield put(push('/dashboard'));
          } else {
            yield put(push(`/dashboard/inbox/${inboxType}/${inboxId}`));
          }
          break;

        case 'toMarketplace':
          yield put(push('/dashboard/marketplace'));
          break;

        case 'toLoan':
          yield put(push(`/dashboard/property-details/${loanId}`));
          break;

        case 'toOauth':
          yield put(push(`/authorize?${makeUrlFromObject(oAuthParams)}`));
          break;

        default:
          break;
      }
    } else {
      if (user.tfa?.secondStepRequired) {
        yield put(push('/confirm-auth'));
      } else if (redirectPath && redirectPath.indexOf('team-member') === -1) {
        if (redirectPath.split('/')[1] === 'property-details') {
          yield put(push(`/dashboard${redirectPath}`));
        } else {
          yield put(push(redirectPath));
        }
      } else {
        yield put(push('/dashboard'));
      }

      if ('Notification' in window && 'ServiceWorker' in window) {
        if (window.Notification.permission === 'default') {
          yield put(showModal(modals.REQUEST_NOTIFICATIONS));
        } else if (window.Notification.permission === 'granted') {
          yield call(requestNotifications);
        }
      }
    }

    if (loanId) {
      yield put(push(`/dashboard/property-details/${loanId}`));
    }

    intercomIdentify(getIntercomSettings(userExpand));

    yield put(socketRequest());
  } catch (e) {
    console.error(e);
    if (getError(e)[0] === 'User account is disabled.') {
      yield put(loginFailure({}));
      yield put(
        showModal(modals.INFO_MODAL, {
          size: 'sm',
          infoType: INFO_MODAL_TYPE.WARNING,
          title: 'Your trial has ended',
          text:
            '<a href="https://app.hubspot.com/meetings/tyler534/discovery-call" target="_blank">Book a meeting</a> ' +
            'with our sales rep or contact our team via email at ' +
            '<a href="mailto:sales@realatom.com">sales@realatom.com</a>',
          buttonText: 'OK',
          disableClose: true,
        })
      );
    } else {
      yield put(loginFailure({ message: getError(e) }));
    }
  }
}

/* eslint-disable  */

function* requestNotifications(action) {
  const withWelcomeRequest = action?.payload?.withWelcomeRequest;
  try {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        messaging
          .getToken()
          .then((currentToken) => {
            console.log(currentToken, 'fcm token');

            if (currentToken) {
              sendTokenToServer(currentToken);

              if (withWelcomeRequest) {
                new Notification('Your RealAtom push notifications will look like this');
              }
            } else {
              console.warn("Can't get token");
              setTokenSentToServer(false);
            }
          })
          .catch((err) => {
            console.warn('An error occurred while receiving the token.', err);
            setTokenSentToServer(false);
          });
      })
      .catch((err) => {
        console.warn('Could not get permission to display notifications.', err);
      });
  } catch (e) {
    console.error(e, 'error 1234');
  }
}

/* eslint-enable  */

function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer(currentToken)) {
    console.log('Sending the token to the server ...');

    API.axiosApi.post('devices', {
      registration_id: currentToken,
      type: 'web',
    });

    setTokenSentToServer(currentToken);
  } else {
    console.log('The token has already been sent to the server.');
  }
}

function* checkResetPasswordExpired(action) {
  const { uid, token, loanId, listingId } = action.payload;

  try {
    yield API.axiosApi.post('auth/password/reset/check', {
      uid,
      token,
    });

    if (listingId) {
      yield put(push(`/set-password-listing?uid=${uid}&token=${token}`));
    } else {
      yield put(push(`/set-new-password?uid=${uid}&token=${token}&loanId=${loanId}`));
    }
  } catch (e) {
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.PROCESS,
        title: 'Reset password',
        text: 'This password reset link has expired',
        buttonText: 'Request a new password',
        onButtonClick: (dispatch) => {
          dispatch(push('/password-reset'));
        },
      })
    );
    yield put(push('/login'));
  }
}

function* registrationEntrpiseLender(action) {
  yield put(hideModal());
  try {
    const { uuid, password, loanId, withRedirectToMarketplace } = action.payload;
    const {
      data: { response },
    } = yield API.axiosApi.post('auth/password/enterprise/set', {
      uuid,
      new_password: password,
    });
    const { user } = response;
    yield put(enterpriseLenderRegistrationSuccess());
    yield put(
      loginSuccess({
        ...user,
        address1: response.address1,
        country: response.country,
        state: response.state,
        zip: response.zip,
        title: response.title,
        city: response.city,
        isNeedUpdateCriteria: response.isNeedUpdateCriteria,
        criteriaUpdatedAt: response.criteriaUpdatedAt,
      })
    );

    analytics(IDENTIFY, user.id, { name: user.firstName, email: user.email });
    intercomIdentify(getIntercomSettings(user));
    yield put(socketRequest());

    if (withRedirectToMarketplace) {
      yield put(push('/dashboard/marketplace'));
    } else if (loanId) {
      yield put(push(`/dashboard/property-details/${loanId}`));
    } else {
      yield put(push('/dashboard'));
    }

    if (!user.profileFilled) {
      yield put(push(`/finish-registration${loanId ? `?loanId=${loanId}` : ''}`));
    }
  } catch (e) {
    console.log(e);
    yield put(enterpriseLenderRegistrationFailure({ message: getError(e) }));
  }
}

function* requestPermission() {
  try {
    yield call(API.axiosApi.post, '/auth/team/request_permission/', {});
    yield put(requestPermissionSuccess());
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        title: 'Permission has been successfully requested',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        buttonText: 'OK',
      })
    );
  } catch (e) {
    yield put(requestPermissionFailure());
  }
}

function* shareOffer(action) {
  try {
    const { member, negotiationId, offerId, loanId } = action.payload;
    yield call(
      API.axiosApi.post,
      `/loans/${loanId}/negotiations/${negotiationId}/offers/${offerId}/share`,
      { recipient: member }
    );
    yield put(shareOfferSuccess());
    yield put(
      showModal(modals.INFO_MODAL, {
        title: 'Success',
        text: 'You have successfully shared the quote',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        buttonText: 'OK',
        size: 'sm',
      })
    );
  } catch (error) {
    yield put(shareOfferFailure());
  }
}

function* listingLogin(action) {
  const { values, listingId } = action.payload;
  const { email, password } = values;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'auth/login', {
      email,
      password,
    });

    const { user, draftLoansCount, submittedLoansCount, postedLoansCount } = response;

    const userExpand = {
      ...user,
      draftLoansCount,
      submittedLoansCount,
      postedLoansCount,
    };

    yield put(
      listingLoginSuccess({
        ...user,
        address1: response.address1,
        country: response.country,
        state: response.state,
        zip: response.zip,
        title: response.title,
        city: response.city,
        isNeedUpdateCriteria: response.isNeedUpdateCriteria,
        criteriaUpdatedAt: response.criteriaUpdatedAt,
      })
    );

    yield put(hideModal());

    analytics(IDENTIFY, user.id, { name: user.firstName, email: user.email });
    if ('Notification' in window && 'ServiceWorker' in window) {
      if (window.Notification.permission === 'default') {
        yield put(showModal(modals.REQUEST_NOTIFICATIONS));
      } else if (window.Notification.permission === 'granted') {
        yield call(requestNotifications);
      }
    }
    intercomIdentify(getIntercomSettings(userExpand));
    yield put(socketRequest());

    if (user.accountType === BORROWER) {
      const loanResponse = yield call(
        API.axiosApi.post,
        `/listings/${listingId}/create_loan`
      );
      yield put(push(`/dashboard/loan-creation/${loanResponse.data.response.loanPk}`));
    } else {
      yield put(push(`/dashboard/listing/${listingId}`));
    }
  } catch (error) {
    yield put(listingLoginFailure());
  }
}

function* listingRegistration(action) {
  const { values, listingId } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      'auth/register',
      decamelizeKeys({ ...values, accountType: BORROWER })
    );
    // TODO: Implement request to create loan based on listing
    const { profile } = response;
    yield put(listingRegistrationSuccess({ profile }));
    yield put(socketRequest());
    analytics(IDENTIFY, profile.id, { name: profile.firstName, email: profile.email });
    intercomIdentify(getIntercomSettings(profile));
    const loanResponse = yield call(
      API.axiosApi.post,
      `/listings/${listingId}/create_loan`
    );
    yield put(push(`/dashboard/loan-creation/${loanResponse.data.response.loanPk}`));
  } catch (e) {
    console.error(e);
    yield put(listingRegistrationFailure({ message: getError(e) }));
  }
}

function* getContacts() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, 'contacts?no_page=true');
    yield put(getContactsSuccess(response));
  } catch (e) {
    yield put(getContactsFailure({ message: getError(e) }));
  }
}

function* getContactsTags() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/contacts/tags');
    yield put(getContactsTagsSuccess(response.tags));
  } catch (e) {
    yield put(getContactsTagsFailure({ message: getError(e) }));
  }
}

function* getContactsTypes() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/contacts/types');
    yield put(getContactsTypesSuccess(response.contactTypes));
  } catch (e) {
    yield put(getContactsTypesFailure({ message: getError(e) }));
  }
}

function* resendInvite(action) {
  try {
    const { email } = action.payload;
    yield call(API.axiosApi.post, '/auth/resend-invite', { email });
    yield put(resendInviteSuccess());
  } catch (e) {
    yield put(resendInviteFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.REGISTRATION_REQUEST, registration),
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGOUT_REQUEST, logout),
  takeLatest(types.BACK_TO_LOGIN_REQUEST, backToLogin),
  takeEvery(types.EMAIL_EXISTS_REQUEST, checkEmail),
  takeLatest(types.NOTIFICATIONS_REQUEST, requestNotifications),
  takeLatest(types.SUPPORT_REQUEST, support),
  takeLatest(types.POST_INBOX_ANSWER_REQUEST, postAnswer),
  takeLatest(types.VERIFY_LENDER, verifyLender),
  takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPassword),
  takeLatest(types.PASSWORD_RESET_REQUEST, resetPassword),
  takeLatest(types.SET_PASSWORD_REQUEST, setPassword),
  takeLatest(types.CONFIRM_EMAIL_REQUEST, confirmEmail),
  takeLatest(types.CHANGE_USER_PROFILE_REQUEST, changeProfile),
  takeLatest(types.CHECK_RESET_PASSWORD_REQUEST, checkResetPasswordExpired),
  takeLatest(types.ENTERPRISE_LENDER_REGISTRATION_REQUEST, registrationEntrpiseLender),
  takeLatest(types.REQUEST_PERMISSION_REQUEST, requestPermission),
  takeLatest(types.SHARE_OFFER_REQUEST, shareOffer),

  takeLatest(types.LISTING_LOGIN_REQUEST, listingLogin),
  takeLatest(types.LISTING_REGISTRATION_REQUEST, listingRegistration),
  takeLatest(types.GET_CONTACTS_REQUEST, getContacts),
  takeLatest(types.GET_CONTACTS_TAGS_REQUEST, getContactsTags),
  takeLatest(types.GET_CONTACTS_TYPES_REQUEST, getContactsTypes),
  takeLatest(types.RESEND_INVITE_REQUEST, resendInvite),
];
