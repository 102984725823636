import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';

import {
  getLoanLendersSuccess,
  getLoanLendersFailure,
  resendLenderInvitationSuccess,
  resendLenderInvitationFailure,
  revokeAccessSuccess,
  revokeAccessFailure,
  getLoanLendersStatsSuccess,
  getLoanLendersStatsFailure,
} from './actions';

function* getLoanLenders(action) {
  try {
    const { loanId, query } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/loans/${loanId}/lenders-list${query ? `?${query.replace('?', '')}` : ''}`
    );
    yield put(getLoanLendersSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(getLoanLendersFailure());
  }
}

function* getLoanLendersStats(action) {
  try {
    const { loanId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/loans/${loanId}/stats`);
    yield put(getLoanLendersStatsSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(getLoanLendersStatsFailure());
  }
}

function* resendInvitation(action) {
  try {
    const { lenderId, loanId } = action.payload;
    yield call(
      API.axiosApi.post,
      `/loans/${loanId}/lenders-list/${lenderId}/resend/`,
      {}
    );
    yield put(resendLenderInvitationSuccess());
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'You have successfully resent the invitation',
      })
    );
  } catch (e) {
    console.log(e);
    yield put(resendLenderInvitationFailure());
  }
}

function* revokeAccess(action) {
  try {
    const { loanId, lender, query } = action.payload;
    yield call(API.axiosApi.post, `/loans/${loanId}/lenders-list/${lender}/revoke/`, {});
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/loans/${loanId}/lenders-list${query ? `?${query.replace('?', '')}` : ''}`
    );
    yield put(revokeAccessSuccess(response));
  } catch (e) {
    yield put(revokeAccessFailure(e));
  }
}

export default [
  takeLatest(types.GET_LOAN_LENDERS_REQUEST, getLoanLenders),
  takeLatest(types.GET_LOAN_LENDERS_STATS_REQUEST, getLoanLendersStats),
  takeLatest(types.RESEND_LENDER_INVITATION_REQUEST, resendInvitation),
  takeLatest(types.REVOKE_ACCESS_REQUEST, revokeAccess),
];
