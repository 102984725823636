import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { Form, Field } from 'react-final-form';
import {
  LenderSourceTypeSwitch,
  LenderSelect,
} from 'components/Modals/ManualOfferModal/components';
import { LENDER_SOURCE } from 'components/Modals/ManualOfferModal/constants';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { Input, Condition } from 'components/Form';
import { delay, simpleMemoize } from 'lib/utils';
import { checkLenderDirectoryEmailRequest } from 'features/Directory/pages/LenderDirectory/actions';
import { validateEmail } from 'utils/validate';
import { normalizeNumbers } from 'lib/normalizers';
import {
  submitManualTermsheetRequest,
  editManualTermsheetRequest,
  deleteManualTermsheetRequest,
} from 'features/LoanTermsheets/actions';
import { hideModal } from 'actions/modalActions';
import { equals } from 'ramda';
import { BucketIcon } from 'components/SvgIcons';

import { AddLenderForm, FileAttachment } from './components';

const ManualTermsheetModal = ({
  invitedLenders,
  createTermsheet,
  isEdit,
  lenderFirstName,
  lenderLastName,
  lenderEmail,
  lenderCompany,
  initialValues,
  editTermsheet,
  termsheetId,
  deleteTermsheet,
  invitedLenderId,
  validateEmailAsync,
}) => {
  const submissionFunction = (values) => {
    if (isEdit) {
      editTermsheet(values, initialValues, termsheetId);
    } else {
      createTermsheet(values, invitedLenderId);
    }
  };

  return (
    <Form
      onSubmit={submissionFunction}
      initialValues={initialValues}
      validate={formValidation}
    >
      {({ handleSubmit, values: formValues, errors, touched, form: { change } }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <ModalWithContentWrapper>
            <ModalHeading>{isEdit ? 'Edit Term Sheet' : 'New Term Sheet'}</ModalHeading>
            <ModalBody>
              {!isEdit && <LenderSourceTypeSwitch />}
              <FormContainer>
                {isEdit ? (
                  <LenderRepresentation
                    lenderFirstName={lenderFirstName}
                    lenderLastName={lenderLastName}
                    lenderEmail={lenderEmail}
                    lenderCompany={lenderCompany}
                  />
                ) : (
                  <div>
                    <Condition when="lenderSourceType" is={LENDER_SOURCE.NEW}>
                      <AddLenderForm validateEmail={validateEmailAsync} />
                    </Condition>
                    <Condition
                      when="lenderSourceType"
                      is={LENDER_SOURCE.INVESTMENT_SOURCES}
                    >
                      <LenderSelect lendersList={invitedLenders} />
                    </Condition>
                  </div>
                )}

                <div>
                  <FieldWrapper>
                    <FieldTitle>
                      Accepted Rate <span>*</span>
                    </FieldTitle>
                    <PercentField>
                      <Field component={Input} name="interest" parse={normalizeNumbers} />
                    </PercentField>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FieldTitle>
                      Documents <span>*</span>
                    </FieldTitle>
                    <FieldDescription>
                      required to upload at least one of the documents
                    </FieldDescription>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FieldDescription>Original document:</FieldDescription>
                    <Field name="originalDocument" render={() => null} />
                    <FileAttachment
                      onDrop={(file) => {
                        change('originalDocument', file);
                      }}
                      removeDocument={() => {
                        change('originalDocument', null);
                      }}
                      document={formValues.originalDocument}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <FieldDescription>Signed document:</FieldDescription>
                    <Field name="signedDocument" render={() => null} />
                    <FileAttachment
                      onDrop={(file) => {
                        change('signedDocument', file);
                      }}
                      removeDocument={() => {
                        change('signedDocument', null);
                      }}
                      document={formValues.signedDocument}
                    />
                  </FieldWrapper>
                  {(touched.signedDocument || touched.originalDocument) &&
                    errors.documents && (
                      <ErrorDescription>{errors.documents}</ErrorDescription>
                    )}
                </div>
              </FormContainer>
            </ModalBody>
            <ModalActions>
              <Button secondary type="submit">
                {isEdit ? 'Submit' : 'Add Term Sheet'}
              </Button>
              {isEdit && (
                <DltBtn
                  type="button"
                  data-termsheetid={termsheetId}
                  onClick={deleteTermsheet}
                >
                  <BucketIcon />
                  DELETE
                </DltBtn>
              )}
            </ModalActions>
          </ModalWithContentWrapper>
        </FormWrapper>
      )}
    </Form>
  );
};

function mapStateToProps(state, props) {
  const {
    loanTermsheets: { termsheetsMap },
  } = state;
  const { termsheetId } = props;
  const editedTermsheet = termsheetsMap?.[termsheetId];

  const initialValues = {};

  if (editedTermsheet) {
    initialValues.interest = editedTermsheet.acceptedRate;
    initialValues.originalDocument = editedTermsheet.termsheet
      ? { name: editedTermsheet.termsheetName }
      : null;
    initialValues.signedDocument = editedTermsheet.signedTermsheet
      ? { name: editedTermsheet.signedTermsheetName }
      : null;
  } else {
    initialValues.lenderSourceType = LENDER_SOURCE.NEW;
    initialValues.originalDocument = null;
    initialValues.signedDocument = null;
  }

  return {
    isEdit: !!editedTermsheet,
    termsheetId: editedTermsheet?.id,
    lenderFirstName: editedTermsheet?.lenderFirstName,
    lenderLastName: editedTermsheet?.lenderLastName,
    lenderEmail: editedTermsheet?.lenderEmail,
    lenderCompany: editedTermsheet?.lenderCompany,
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    validateEmailAsync: simpleMemoize(async (email) => {
      if (!email) return 'Email is required';

      if (!validateEmail(email)) return 'Invalid email address';

      if (email) {
        const res = await delay(1000).then(() =>
          new Promise((resolve, reject) => {
            dispatch(
              checkLenderDirectoryEmailRequest({
                email,
                resolve,
                reject,
                checkLenderId: true,
              })
            );
          }).then(
            (v) => v,
            ({ error, data }) => {
              // If email validation returns existent lender id, we intercept it
              // and store in modal state. In this particular case email existence is not
              // an error
              if (data?.result !== 'exists') {
                return error;
              }

              return undefined;
            }
          )
        );
        return res;
      }
    }),

    createTermsheet: (values, invitedLenderId) => {
      const {
        lenderSourceType,
        originalDocument,
        signedDocument,
        selectLender,
        customLenderFirstName,
        customLenderLastName,
        customLenderEmail,
        customLenderCompany,
        interest,
      } = values;
      const request = new FormData();

      const isNewLender = lenderSourceType === LENDER_SOURCE.NEW && !invitedLenderId;
      const requestedLenderId = invitedLenderId || selectLender?.lender?.lender;

      request.append('interest', interest);

      if (!isNewLender) {
        request.append('custom_lender', requestedLenderId);
      } else {
        request.append('custom_lender_first_name', customLenderFirstName);
        request.append('custom_lender_last_name', customLenderLastName);
        request.append('custom_lender_email', customLenderEmail);
        request.append('custom_lender_company', customLenderCompany);
        request.append('invite_this_lender', true);
      }

      if (originalDocument) {
        request.append('termsheet', originalDocument);
      }

      if (signedDocument) {
        request.append('signed_termsheet', signedDocument);
      }
      dispatch(
        submitManualTermsheetRequest({
          request,
          lender: isNewLender ? 'new' : requestedLenderId,
        })
      );

      dispatch(hideModal());
    },

    editTermsheet: (values, initialValues, termsheetId) => {
      const request = new FormData();

      if (!equals(values.interest, initialValues.interest)) {
        request.append('interest', values.interest);
      }
      if (!equals(values.originalDocument, initialValues.originalDocument)) {
        request.append('termsheet', values.originalDocument);
      }
      if (!equals(values.signedDocument, initialValues.signedDocument)) {
        request.append('signed_termsheet', values.signedDocument);
      }

      dispatch(editManualTermsheetRequest({ request, termsheetId }));
      dispatch(hideModal());
    },

    deleteTermsheet: (e) => {
      const { termsheetid } = e.target.dataset;
      dispatch(deleteManualTermsheetRequest({ termsheetId: termsheetid }));
      dispatch(hideModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualTermsheetModal);

const LenderRepresentation = ({
  lenderFirstName,
  lenderLastName,
  lenderEmail,
  lenderCompany,
}) => (
  <LenderRepresentationWrapper>
    <Text>Lender: </Text>
    <LenderRepresentationName>
      {lenderFirstName} {lenderLastName}
    </LenderRepresentationName>
    <a href={`mailto:${lenderEmail}`}>{lenderEmail}</a>
    <LenderRepresentationName>{lenderCompany}</LenderRepresentationName>
  </LenderRepresentationWrapper>
);

const formValidation = (values) => {
  const errors = {};

  errors.interest = values.interest ? undefined : 'Interest is required';
  errors.documents =
    values.originalDocument || values.signedDocument
      ? undefined
      : 'At least one of the documents is required';

  if (values.lenderSourceType === LENDER_SOURCE.NEW) {
    errors.customLenderFirstName = values.customLenderFirstName
      ? undefined
      : 'First name is required';
    errors.customLenderLastName = values.customLenderLastName
      ? undefined
      : 'Last name is required';
    errors.customLenderCompany = values.customLenderCompany
      ? undefined
      : 'Company name is required';
  } else if (values.lenderSourceType === LENDER_SOURCE.INVESTMENT_SOURCES) {
    errors.selectLender = values?.selectLender?.lender
      ? undefined
      : 'Please choose investment source';
  }

  return errors;
};

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & > div {
    flex: 1 1 auto;
    flex-shrink: 0;
    flex-basis: 50%;

    &:first-child {
      padding-right: 20px;
    }
    &:last-child {
      padding-left: 20px;
    }
  }
`;

const PercentField = styled.div`
  width: 70px;
  position: relative;

  &::after {
    content: '%';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const FieldTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 8px;

  span {
    color: #e32019;
  }
`;

const FieldDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #778598;
`;

const ErrorDescription = styled(FieldDescription)`
  color: #ff6f57;
`;

const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

const LenderRepresentationWrapper = styled.div`
  && > * {
    margin-bottom: 8px;
  }

  a {
    display: inline-block;
  }
`;

const LenderRepresentationName = styled(Text)`
  font-weight: bold;
`;

const DltBtn = styled.button`
  position: absolute;
  right: 24px;
  top: 50%;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background-color: #fafafb;
  color: #778598;
  font-size: 12px;
  cursor: pointer;

  svg {
    margin-right: 4px;
  }
`;
